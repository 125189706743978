import revive_payload_client_07lICnukRx from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_sass@1.62.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_B6cuCJAfMN from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_sass@1.62.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_X10nmuNkGh from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_sass@1.62.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_efL2oer7Bl from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.21_vite@5.4.11_vue@3.5.12/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yLo3CLQFrE from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_sass@1.62.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Y4FXnWbTKp from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_sass@1.62.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_HMfiz41v9t from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_sass@1.62.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_0NNToO0wJt from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_sass@1.62.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_jy21AhLdsS from "/opt/build/repo/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_sass@1.62.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_fiCw1JXXk1 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+prismic@3.0.3_vue@3.5.12/node_modules/@nuxtjs/prismic/dist/runtime/plugin.client.mjs";
import plugin_DtGuP3fByi from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+prismic@3.0.3_vue@3.5.12/node_modules/@nuxtjs/prismic/dist/runtime/plugin.mjs";
import primevue_plugin_egKpok8Auk from "/opt/build/repo/.nuxt/primevue-plugin.mjs";
import plugin_client_nOheiomYLd from "/opt/build/repo/node_modules/.pnpm/nuxt-primevue@0.3.1_vue@3.5.12/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import analytics_client_mtqVw3YRXk from "/opt/build/repo/plugins/analytics.client.ts";
import emitter_eaMgM0oZTb from "/opt/build/repo/plugins/emitter.ts";
import scroll_client_HJUo2UKx9n from "/opt/build/repo/plugins/scroll.client.ts";
export default [
  revive_payload_client_07lICnukRx,
  unhead_B6cuCJAfMN,
  router_X10nmuNkGh,
  _0_siteConfig_efL2oer7Bl,
  payload_client_yLo3CLQFrE,
  navigation_repaint_client_Y4FXnWbTKp,
  check_outdated_build_client_HMfiz41v9t,
  chunk_reload_client_0NNToO0wJt,
  components_plugin_KR1HBZs4kY,
  prefetch_client_jy21AhLdsS,
  plugin_client_fiCw1JXXk1,
  plugin_DtGuP3fByi,
  primevue_plugin_egKpok8Auk,
  plugin_client_nOheiomYLd,
  analytics_client_mtqVw3YRXk,
  emitter_eaMgM0oZTb,
  scroll_client_HJUo2UKx9n
]