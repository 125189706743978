import { defineNuxtPlugin } from '#app/nuxt'
import { LazyPrimeButton, LazyPrimeAccordion, LazyPrimeAccordionTab, LazyPrimeCard, LazyPrimeDeferredContent, LazyPrimeMenubar, LazyPrimeImage } from '#components'
const lazyGlobalComponents = [
  ["PrimeButton", LazyPrimeButton],
["PrimeAccordion", LazyPrimeAccordion],
["PrimeAccordionTab", LazyPrimeAccordionTab],
["PrimeCard", LazyPrimeCard],
["PrimeDeferredContent", LazyPrimeDeferredContent],
["PrimeMenubar", LazyPrimeMenubar],
["PrimeImage", LazyPrimeImage],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
