import {defineNuxtPlugin, useRuntimeConfig} from '#app';
import {useCookie, useRouter} from '#imports';


export interface Gtag {
    (command: 'config', targetId: string, config?: Record<string, any>): void

    (command: 'event', eventName: string & {}, eventParams?: Record<string, any>): void

    (command: 'set', targetId: string, config: string | boolean | Record<string, any>): void

    (command: 'set', config: Record<string, any>): void

    (command: 'get', targetId: string, fieldName: string, callback?: (field?: string | Record<string, any>) => void): void

    (command: 'consent', consentArg: string, consentParams: Record<string, any>): void

    (command: 'js', config: Date): void
}


export default defineNuxtPlugin((nuxtApp) => {
    const runtimeConfig = useRuntimeConfig();
    const config = runtimeConfig.public;

    // @ts-expect-error: `dataLayer` is not defined
    window.dataLayer = window.dataLayer || []

    function gtag(..._args: any[]) {
        // eslint-disable-next-line prefer-rest-params
        (window as any).dataLayer.push(arguments)
    }


    // console.log('Analytics : enableGoogleAnalytics');
    // see : https://github.com/nuxt-community/google-gtag-module/blob/master/lib/plugin.js
    // see : https://developers.google.com/analytics/devguides/collection/gtagjs/ip-anonymization
    // see GTAG Doc : https://developers.google.com/tag-platform/devguides?hl=fr
    // see GTAG Conf : https://developers.google.com/tag-platform/gtagjs/reference?hl=fr
    // see : https://developers.google.com/tag-platform/gtagjs/configure?hl=fr

    // See : https://developers.google.com/tag-platform/devguides/privacy?hl=fr
    gtag('consent', 'default', {
        ad_storage: "denied",               // Permet le stockage, tel que les cookies, lié à la publicité.
        ad_user_data: "denied",             // Définit le consentement des utilisateurs pour l'envoi de données à Google à des fins de publicité en ligne.
        ad_personalization: "denied",       // Définit le consentement pour la publicité personnalisée.
        personalization_storage: "denied",  // Active le stockage lié à la personnalisation (recommandations de vidéos, par exemple).

        analytics_storage: "granted",       // Permet le stockage, tel que les cookies, lié à l'analyse (par exemple, la durée des visites).
        functionality_storage: "granted",   // Active le stockage compatible avec les fonctionnalités du site Web ou de l'application, comme les paramètres linguistiques.
        security_storage: "granted",        // Permet le stockage lié à la sécurité, comme la fonctionnalité d'authentification, la prévention des fraudes et d'autres mécanismes de protection des utilisateurs
        // wait_for_update: 1000,
    });

    gtag('config', config.GTM_MEASUREMENT_ID);

    gtag('set', config.GA_MEASUREMENT_ID, {
        // debug: false,

        id: '',
        layer: 'dataLayer',
        variables: {},

        anonymize_ip: true, // anonymize IP (not required in GA4)
        respectDoNotTrack: true,

        allow_google_signals: false,
        allow_ad_personalization_signals: false,

        send_page_view: false,
        pageTracking: false,
        pageViewEventName: 'nuxtRoute',
    });

    gtag('js', new Date());

    //gtag('event', 'click', {
    //   'event_category': 'myCategory',
    //   'event_label': 'myLabel',
    //   'value': 'myValue'
    // });

    const router = useRouter();
    router.afterEach((to) => {
        const options = {
            'page_path': to.fullPath,
            'location_path': window.location.origin + to.fullPath
        };

        gtag('set', config.GA_MEASUREMENT_ID, options);

        // console.log('Analytics Plugin : PageStart', config.GA_MEASUREMENT_ID, options);
    })


    useHead({
        script: [
            // {src: `https://www.googletagmanager.com/gtag/js?id=${config.GA_MEASUREMENT_ID}`, async: true, defer: false},
            // {src: `https://www.googletagmanager.com/gtag/js?id=${config.AW_MEASUREMENT_ID}`, async: true, defer: true},
            {src: `https://www.googletagmanager.com/gtm.js?id=${config.GTM_MEASUREMENT_ID}`, async: true, defer: true},   // Required for conversion tracking
        ],
    })


    return {
        provide: {
            gtag: gtag
        }
    }
})


