import { default as preview8jLe0uRclcMeta } from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+prismic@3.0.3_vue@3.5.12/node_modules/@nuxtjs/prismic/dist/runtime/preview.vue?macro=true";
import { default as a_45proposIIXjlPC1SAMeta } from "/opt/build/repo/pages/a-propos.vue?macro=true";
import { default as avis_45clientsQWdzKa2A0TMeta } from "/opt/build/repo/pages/avis-clients.vue?macro=true";
import { default as _91uid_93S6twjIoFImMeta } from "/opt/build/repo/pages/blog/[uid].vue?macro=true";
import { default as indexmFsYN4t0XfMeta } from "/opt/build/repo/pages/blog/index.vue?macro=true";
import { default as data_45protectionUcYkTzlGIPMeta } from "/opt/build/repo/pages/data-protection.vue?macro=true";
import { default as devis_45rgpdXlHmQuOGG2Meta } from "/opt/build/repo/pages/devis-rgpd.vue?macro=true";
import { default as entreprisesZOJkMteCtFMeta } from "/opt/build/repo/pages/entreprises.vue?macro=true";
import { default as formationsLHxTE7ZTYQMeta } from "/opt/build/repo/pages/formations.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as medico_45socialHj65A4AMSjMeta } from "/opt/build/repo/pages/medico-social.vue?macro=true";
import { default as privacyjCuzG6sq6pMeta } from "/opt/build/repo/pages/privacy.vue?macro=true";
import { default as succeedwoOfuswS9lMeta } from "/opt/build/repo/pages/succeed.vue?macro=true";
import { default as verifyPPsbf6HYMxMeta } from "/opt/build/repo/pages/verify.vue?macro=true";
export default [
  {
    name: "prismic-preview",
    path: "/blog/preview/",
    component: () => import("/opt/build/repo/node_modules/.pnpm/@nuxtjs+prismic@3.0.3_vue@3.5.12/node_modules/@nuxtjs/prismic/dist/runtime/preview.vue")
  },
  {
    name: "a-propos",
    path: "/a-propos",
    component: () => import("/opt/build/repo/pages/a-propos.vue")
  },
  {
    name: "avis-clients",
    path: "/avis-clients",
    component: () => import("/opt/build/repo/pages/avis-clients.vue")
  },
  {
    name: "blog-uid",
    path: "/blog/:uid()",
    component: () => import("/opt/build/repo/pages/blog/[uid].vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/opt/build/repo/pages/blog/index.vue")
  },
  {
    name: "data-protection",
    path: "/data-protection",
    component: () => import("/opt/build/repo/pages/data-protection.vue")
  },
  {
    name: "devis-rgpd",
    path: "/devis-rgpd",
    component: () => import("/opt/build/repo/pages/devis-rgpd.vue")
  },
  {
    name: "entreprises",
    path: "/entreprises",
    component: () => import("/opt/build/repo/pages/entreprises.vue")
  },
  {
    name: "formations",
    path: "/formations",
    component: () => import("/opt/build/repo/pages/formations.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "medico-social",
    path: "/medico-social",
    component: () => import("/opt/build/repo/pages/medico-social.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/opt/build/repo/pages/privacy.vue")
  },
  {
    name: "succeed",
    path: "/succeed",
    component: () => import("/opt/build/repo/pages/succeed.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/opt/build/repo/pages/verify.vue")
  }
]