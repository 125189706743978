export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"hid":"description","name":"description","content":"Logiciel RGPD avec un accompagnement juridique pour votre mise en conformité RGPD."},{"hid":"og:type","property":"og:type","content":"website"},{"hid":"og:image","property":"og:image","content":"https://blockproof.fr/img/image-url.jpg"},{"name":"msapplication-TileColor","content":"#da532c"},{"name":"theme-color","content":"#ffffff"}],"link":[{"rel":"sitemap","type":"application/xml","href":"/sitemap.xml"},{"rel":"preconnect","href":"https://fonts.googleapis.com"},{"rel":"preconnect","href":"https://fonts.gstatic.com"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"},{"rel":"apple-touch-icon","sizes":"152x152","href":"/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicon-16x16.png"},{"rel":"manifest","href":"/site.webmanifest"},{"rel":"mask-icon","href":"/safari-pinned-tab.svg","color":"#5bbad5"}],"style":[],"script":[{"src":"https://cdn.jsdelivr.net/npm/lazysizes@5.2.2/lazysizes.min.js","async":true,"defer":false},{"src":"https://consent.cookiefirst.com/banner.js","data-cookiefirst-key":"1eb48730-7e64-4d4f-8123-2013c0b40286","async":true,"defer":true}],"noscript":[],"title":"Blockproof","htmlAttrs":{"lang":"fr"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = true